"use client";

import { gtmSender } from "@/helpers";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useRef } from "react";

export default function CampaignTracker({}: {
}) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const cq = searchParams.get('cq'); // Campaign Query
  const campaignId = useRef<string>();

  useEffect(() => {
    if(cq && campaignId.current !== cq) {
      campaignId.current = cq;
      const sessionCq = sessionStorage.getItem('campaign_id') ?? null;
      
      if(sessionCq !== cq) {
        gtmSender({
          "event": "campaign_track",
          "campaignInformation": {
            "id": cq,
            "path": pathname,
            "date": new Date().toISOString(),
          }
        });
  
        sessionStorage.setItem('campaign_id', cq);
      }
    }
  }, [
    cq,
    campaignId,
    pathname
  ]);

  return (<></>);
}
